.benefits-section {
    background-color: $nero;
    padding-bottom: 50px;
    padding-top: 38px;

    @include mq($tablet-desktop-xxl) {
        padding: 40px 50px;
    }

    @include mq($tablet-max) {
        padding-bottom: 29px;
    }

    &__container {
        max-width: $max-width-smallest;
        margin: 0 auto;
    }

    &__title {
        @include mq($tablet-max) {
            padding: 0 27px;
            margin-bottom: 34px;
        }
    }

    &__main-title {
        color: $white;
        margin-bottom: 22px;
    }

    &__title-opposite {
        display: block;
        color: transparent;
        -webkit-text-stroke: 1px $white;
    }

    &__content {
        display: flex;

        @include mq($tablet-max) {
            display: block;
        }
    }

    &__items {
        padding-left: 125px;
        width: 48%;

        @include mq($tablet-max) {
            padding: 0 35px;
            margin-top: 45px;
        }

        .benefits-section__item:last-child {
            margin-bottom: 0;
        }
    }

    &__image {
        width: 52%;
        height: 610px;

        @include mq($tablet-max) {
            height: 500px;
        }

        @include mq($mobile-max) {
            height: 310px;
        }

        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;

            @include mq($tablet-max) {
                object-position: top;
            }
        }
    }

    &__items,
    &__image {
        @include mq($tablet-max) {
            width: 100%;
        }
    }

    &__item {
        position: relative;
        display: flex;
        align-items: flex-start;
        margin-bottom: 33px;

        @include mq($mobile-max) {
            display: block;
            margin-bottom: 31px;
        }

        img {
            position: absolute;
            top: 0;
            width: 60px;
            height: 50px;

            @include mq($mobile-max) {
                position: relative;
            }
        }

        &.hidden {
            display: none;
        }
    }

    &__item-btn {
        cursor: pointer;
        color: $white;
        background-color: $safety-orange;
        @include body;

        border: none;
        font-family: $heading-pro-treble-heavy;
        text-transform: uppercase;
        padding: 16px 0 12px;
        margin: 43px auto 0;
        outline: none;
        width: 90%;

        @include mq($not-mobile-tablet) {
            display: none;
        }

        @include mq($tablet-max) {
            display: block;
            max-width: 310px;
        }
    }

    &__item-content {
        margin-left: 95px;

        @include mq($mobile-max) {
            margin-left: 0;
            margin-top: 7px;
        }
    }

    &__item-title,
    &__item-paragraph {
        color: $white;
    }

    &__item-title {
        @include h7;

        font-family: $heading-pro-treble-extra-bold;
    }

    &__item-paragraph {
        @include body;

        margin-bottom: 0;
    }
}
