/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.benefits-section {
  background-color: #2d2d2d;
  padding-bottom: 50px;
  padding-top: 38px;
}
@media only screen and (min-width: 1025px) and (max-width: 1400px) {
  .benefits-section {
    padding: 40px 50px;
  }
}
@media only screen and (max-width: 1024px) {
  .benefits-section {
    padding-bottom: 29px;
  }
}
.benefits-section__container {
  max-width: 1280px;
  margin: 0 auto;
}
@media only screen and (max-width: 1024px) {
  .benefits-section__title {
    padding: 0 27px;
    margin-bottom: 34px;
  }
}
.benefits-section__main-title {
  color: #fff;
  margin-bottom: 22px;
}
.benefits-section__title-opposite {
  display: block;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}
.benefits-section__content {
  display: flex;
}
@media only screen and (max-width: 1024px) {
  .benefits-section__content {
    display: block;
  }
}
.benefits-section__items {
  padding-left: 125px;
  width: 48%;
}
@media only screen and (max-width: 1024px) {
  .benefits-section__items {
    padding: 0 35px;
    margin-top: 45px;
  }
}
.benefits-section__items .benefits-section__item:last-child {
  margin-bottom: 0;
}
.benefits-section__image {
  width: 52%;
  height: 610px;
}
@media only screen and (max-width: 1024px) {
  .benefits-section__image {
    height: 500px;
  }
}
@media only screen and (max-width: 767px) {
  .benefits-section__image {
    height: 310px;
  }
}
.benefits-section__image img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  .benefits-section__image img {
    object-position: top;
  }
}
@media only screen and (max-width: 1024px) {
  .benefits-section__items, .benefits-section__image {
    width: 100%;
  }
}
.benefits-section__item {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 33px;
}
@media only screen and (max-width: 767px) {
  .benefits-section__item {
    display: block;
    margin-bottom: 31px;
  }
}
.benefits-section__item img {
  position: absolute;
  top: 0;
  width: 60px;
  height: 50px;
}
@media only screen and (max-width: 767px) {
  .benefits-section__item img {
    position: relative;
  }
}
.benefits-section__item.hidden {
  display: none;
}
.benefits-section__item-btn {
  cursor: pointer;
  color: #fff;
  background-color: #fe7000;
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 16px;
  line-height: 28px;
  border: none;
  font-family: "HeadingProTreble-Heavy", sans-serif;
  text-transform: uppercase;
  padding: 16px 0 12px;
  margin: 43px auto 0;
  outline: none;
  width: 90%;
}
@media only screen and (min-width: 1025px) {
  .benefits-section__item-btn {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .benefits-section__item-btn {
    display: block;
    max-width: 310px;
  }
}
.benefits-section__item-content {
  margin-left: 95px;
}
@media only screen and (max-width: 767px) {
  .benefits-section__item-content {
    margin-left: 0;
    margin-top: 7px;
  }
}
.benefits-section__item-title, .benefits-section__item-paragraph {
  color: #fff;
}
.benefits-section__item-title {
  font-size: 20px;
  line-height: 26px;
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
}
.benefits-section__item-paragraph {
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0;
}